
/** GLOBAL CLASSES **/


/*
body {
    font-family: 'Open Sans', sans-serif;
    color: #535353;
}
*/

.img-fluid {
    max-width: 100% !important;
    height: auto;
}

.form-control:focus {
    box-shadow: none;
}

.login-box .alert{
    padding: 15.5px 20px;
    font-size: 13px;
    border: none !important;
    border-radius: 3px;
    margin-bottom: 25px;
}

.login-box .alert-dismissible .btn-close{
    padding: 18.5px 19px;
    background-size: 8px;
}

.login-box .alert-dismissible .btn-close:focus,
.login-box .alert-dismissible .btn-close:active {
    box-shadow: none;
}

.login-box .password-indicator {
    position: absolute;
    right: 15px;
    top: 17px;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 500ms;
    font-size: 17px;
    color: #999;
}

.login-box .password-wrapper:hover .password-indicator{
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 500ms;
    font-size: 17px;
    color: #555;
}

.login-box .login-popover{
    font-size: 14px;
    color: #777;
    cursor: help;
}

.login-box .login-popover-abs{
    position: absolute;
    right: 15px;
    top: 15px;
}

.popover {
    border: 1px solid #ebeef5 !important;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

.popover-header {
    box-shadow: none;
    border: 0px;
    background: #fff;
    color: #555;
    font-size: 15px;
    font-family: 'Jost', sans-serif;
    padding-bottom: 5px;
}

.popover-body {
    padding: 5px 10px 10px 16px;
    color: #777;
    font-size: 13px;
}

.login-box .form-group label.error{
    font-size: 13px;
    text-align: left;
    color: #535353;
    width: 100%;
}

/** Login 1 start **/




/** Login 22 start **/
.login-22{
    background: #eef1f6;
    font-family: 'Public Sans', sans-serif;
}

.login-22 a {
    text-decoration: none;
}

.login-22 h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'Jost', sans-serif;
}

.login-22 .bg-img{
    background: url('./assets/img/ai-2.jpg') top left repeat;
    background-size: cover;
    top: 0;
    bottom: 0;
    min-height: 100vh;
    text-align: left;
    z-index: 10;
    opacity: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 30px;
}

.login-22 .bg-img:after {
    position: absolute;
    left: -1px;
    top: 0;
    width: 276px;
    height: 100%;
    content: "";
    z-index: -1;
    background: url('./assets/img/img-50.png') top left repeat;
}

.login-22 .form-check-input:checked {
    display: none;
}

.login-22 .form-section{
    min-height: 100vh;
    position: relative;
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.login-22 .form-section .form-box {
    width: 100%;
    position: relative;
}

.login-22 .login-inner-form {
    max-width: 450px;
    color: #535353;
    width: 100%;
    text-align: center;
}

.login-22 .login-inner-form p{
    /*color: #535353;*/
    /*font-size: 16px;*/
    /*margin: 0;*/
}

.login-22 .login-inner-form p a{
    color: #535353;
    font-weight: 500;
}

.login-22 .login-inner-form img {
    margin-bottom: 15px;
    height: 30px;
}

.login-22 .login-inner-form h3 {
    margin: 0 0 30px;
    font-size: 25px;
    font-weight: 400;
    color: #040404;
}

.login-22 .login-inner-form .form-group {
    margin-bottom: 25px;
}

.login-22 .login-inner-form .form-control {
    outline: none;
    width: 100%;
    padding: 10px 25px;
    font-size: 16px;
    outline: 0;
    font-weight: 400;
    color: #535353;
    height: 55px;
    border-radius: 50px;
    border: 1px solid #fff;
}

.login-22 .login-inner-form .btn-md {
    cursor: pointer;
    height: 55px;
    color: #fff;
    padding: 15.5px 50px 14.5px 50px;
    font-size: 17px;
    font-weight: 400;
    font-family: 'Jost', sans-serif;
    border-radius: 50px;
}

.login-22 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-right: 3px;
}

.login-22 .login-inner-form button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.login-22 .login-inner-form .btn-theme.focus, .btn-theme:focus {
    box-shadow: none;
}

.login-22 .login-inner-form .btn-theme {
    background: #ffa637;
    border: none;
    color: #fff;
}

.login-22 .login-inner-form .btn-theme:hover {
    background: #ed972c;
}

.login-22 .login-inner-form .checkbox .terms {
    margin-left: 3px;
}

.login-22 .informeson {
    color: white;
    max-width: 500px;
    margin: 0 20px 0 80px;
}

.login-22 .informeson h1{
    color: white;
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: 600;
    text-transform: capitalize;
}

.login-22 .informeson h1 span{
    color: #ffa637;
}

.login-22 .informeson p{
    color: white;
    margin-bottom: 20px;
    line-height: 28px;
}

.login-22 .social-list a {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    display: inline-block;
    font-size: 20px;
    margin: 2px;
    border-radius: 5%;
    background: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.login-22 .social-list a:hover {
    color: #fff;
}

.login-22 .none-2{
    display: none;
}

.login-22 .btn-section {
    margin-bottom: 30px;
}

.login-22 .informeson .link-btn:hover {
    text-decoration: none;
}

.login-22 .informeson .link-btn {
    background: #fff;
    padding: 9px 30px;
    font-size: 15px;
    border-radius: 3px;
    margin-right: 2px;
    letter-spacing: 0.5px;
    font-weight: 400;
    color: #ffa637;
    font-family: 'Jost', sans-serif;
}

.login-22 .informeson .active {
    background: #ffa637!important;
    color: #fff;
}

.login-22 .login-inner-form .terms{
    margin-left: 3px;
}

.login-22 .login-inner-form .form-check{
    float: left;
    margin-bottom: 0;
    padding-left: 0;
}

.login-22 .login-inner-form .form-check a {
    color: #535353;
    float: right;
}

.login-22 .login-inner-form .form-check-input {
    display: none;
}

.login-22 .login-inner-form .form-check label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 20px;
    top: 0;
    height: 20px;
    margin-left: -25px;
    border: 1px solid #fff;
    border-radius: 50px;
    background-color: #fff;
}

.login-22 .login-inner-form .form-check-label {
    padding-left: 25px;
    margin-bottom: 0;
    font-size: 16px;
    font-family: 'Public Sans', sans-serif;
    cursor:pointer;
}

.login-22 .login-inner-form .checkbox-theme input[type="checkbox"]:checked + label::before {
    background-color: #1C2536;
    border-color: #1C2536;
}

.login-22 .login-inner-form input[type=checkbox]:checked + label:before {
    font-weight: 600;
    color: #fff;
    line-height: 19px;
    font-size: 14px;
    content: "\2713";
    padding-left: 1px;
}

.login-22 .login-inner-form input[type=checkbox], input[type=radio] {
    margin-top: 4px;
}

.login-22 .login-inner-form .checkbox a {
    font-size: 16px;
    color: #535353;
    float: right;
}

/** Social media **/
.login-22 .facebook-bg {
    color: #4867aa;
}

.login-22 .facebook-bg:hover {
    background: #4867aa;
}

.login-22 .twitter-bg {
    color: #33CCFF;
}

.login-22 .twitter-bg:hover {
    background: #33CCFF;
}

.login-22 .google-bg {
    color: #db4437;
}

.login-22 .google-bg:hover {
    background: #db4437;
}

.login-22 .linkedin-bg {
    color: #2392e0;
}

.login-22 .linkedin-bg:hover {
    background: #1c82ca;
}

/** MEDIA **/
@media (max-width: 992px) {
    .login-22 .bg-img {
        display: none;
    }

    .login-22 .form-section{
        padding: 30px 15px;
    }
}
/** Login 22 end **/

